import React from "react"
import Layout, { Head } from "../components/layout"

const NotFoundPage = ({ pageContext }) => {
  const Context = {
    title: pageContext.title || "404: Not found",
    description: pageContext.title || "404: Not found",
  }
  return (
    <Layout>
      <Head Context={Context} />
      <h1>404: Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Layout>
  )
}
export default NotFoundPage
